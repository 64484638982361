import React from 'react';


const SendEmail = () => {


    return (
        <div>
            <h3>If you'd like to contact me</h3>
            <h3>you can email me:</h3>
            <a className="email-btn" href='mailto:sam.loar70@gmail.com?subject=More%20Info%20About%20Your%20Work&body= '>Send Email</a>
        </div>
    )
}

export default SendEmail